import styled from 'styled-components'
import CTA from './CTA'
import PageBannerWrapper from './PageBannerWrapper'
import Wrapper from './Wrapper'

const Container = styled(Wrapper)`
  padding: 75px 30px 50px 30px;
  text-align: center;
`

/**
 * @typedef {object} Props
 * @prop {object} cta
 * @prop {string=} title
 * @prop {string=} description
 */

/**
 * @type {React.FC<Props>}
 */
const PageBannerTextBackground = ({ title, description, cta }) => {
  return (
    <PageBannerWrapper>
      {title && (
        <Container>
          {title && <h1>{title}</h1>}
          {description && <p>{description}</p>}
          {cta && (
            <CTA
              $primary
              to={cta}>
              {cta.text.value}
            </CTA>
          )}
        </Container>
      )}
    </PageBannerWrapper>
  )
}

export default PageBannerTextBackground
